import React, { useState, useEffect } from "react";
import { MdPlayCircle } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "./ApiService";
import Editor from "@monaco-editor/react";
import { Tooltip, Modal, Button, Spinner } from "flowbite-react";
import { BiReset } from "react-icons/bi";
import { FaCloudUploadAlt } from "react-icons/fa";
import { languages } from "./Languages";
import { getEmoji } from "./Emojis";
import { themes } from "./Themes";
import { useTheme } from "./ThemeContext";

const CodeEditor = ({ setRefreshData, proctoring = false }) => {
  const [openModal, setOpenModal] = useState(false);
  const { taskSlug } = useParams();
  const handleCloseModal = () => setOpenModal(false);
  const { darkMode, toggleTheme } = useTheme();

  const [theme, setTheme] = useState("vs-dark");
  const [loading, setloading] = useState(false);
  const [language, setLanguage] = useState("cpp");
  const [submissionsStatus, setSubmissionStatus] = useState([]);
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [code, setCode] = useState("");
  const [result, setresult] = useState("");
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [showWarning, setShowWarning] = useState(false);

  const service = new ApiService();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [defaultCodeFromDB, setDefaultCodeFromDB] = useState(
    languages.map((lang) => ({
      language: lang.value,
      code: lang.defaultCode,
    }))
  );

  const setDefaultCode = (language) => {
    let defaultCodes = localStorage.getItem(`defaultCode-${slug}`);

    if (defaultCodes) {
      try {
        defaultCodes = JSON.parse(defaultCodes);
      } catch (e) {
        defaultCodes = defaultCodeFromDB;
      }
    } else {
      defaultCodes = defaultCodeFromDB; // Use the value fetched from the database
    }

    const defaultCode = defaultCodes.find(
      (lang) => lang.language === language
    )?.code;
    setCode(defaultCode || "");
  };

  useEffect(() => {
    const storedCode = loadFromLocalStorageWithExpiry(
      `code-${language}-${taskSlug}-${slug}`
    );
    if (storedCode) {
      setCode(storedCode);
    } else {
      setDefaultCode(language);
    }
  }, [language]);

  useEffect(() => {
    const storedCode = loadFromLocalStorageWithExpiry(
      `code-${language}-${taskSlug}-${slug}`
    );
    if (storedCode) {
      setCode(storedCode);
    } else {
      setDefaultCode(language);
    }
    setInput("");
    setOutput("");
  }, [slug]);

  function saveToLocalStorageWithExpiry(key, data, expiryInMinutes) {
    const now = new Date();
    const expiryTime = now.getTime() + expiryInMinutes * 60 * 1000;
    const item = {
      data,
      expiry: expiryTime,
    };
    localStorage.setItem(key, JSON.stringify(item));
    console.log(
      `Saved ${key} to LocalStorage with expiration of ${expiryInMinutes} minutes`
    );
  }

  function loadFromLocalStorageWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.data;
  }

  const handleCodeChange = (value) => {
    setCode(value);
    saveToLocalStorageWithExpiry(
      `code-${language}-${taskSlug}-${slug}`,
      value,
      120
    );
  };

  const handleRun = () => {
    setloading(true);
    setOutput("Running...");

    const requestData = {
      language: languages.find((lang) => lang.value === language)?.id,
      code: code,
      input: input,
    };

    service
      .post("/runCode/run", requestData)
      .then((response) => {
        const submissionId = response.submissionId;
        pollSubmissionStatusRun(submissionId);
      })
      .catch((error) => {
        console.error("Error running code:", error);
        if (
          error?.response?.data?.message === "jwt expired" ||
          error?.response?.data?.message === "jwt malformed"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
        setloading(false);
        setOutput("Error occurred while running code.");
      });
  };

  const pollSubmissionStatusRun = (submissionId) => {
    let counter = 0;
    const intervalId = setInterval(() => {
      counter++;
      if (counter > 25) {
        clearInterval(intervalId);
        setloading(false);
        return;
      }

      service
        .get(`/runCode/status/${submissionId}`)
        .then((response) => {
          const status = response.status;
          if (status === "In Queue" || status === "Processing") {
            setOutput(status);
          } else if (status === "Accepted") {
            setOutput(response.output);
            clearInterval(intervalId);
            setloading(false);
          } else if (
            status === "Compilation Error" ||
            status === "Runtime Error" ||
            status === "Time Limit Exceeded" ||
            status === "Memory Limit Exceeded"
          ) {
            setOutput(`Error occurred: ${status}`);
            if (status === "Compilation Error") {
              setOutput(response.compile_output);
            }
            clearInterval(intervalId);
            setloading(false);
          } else {
            setOutput(response.stderr);
            clearInterval(intervalId);
            setloading(false);
          }
        })
        .catch((error) => {
          console.error("Error polling submission status:", error);
          if (
            error?.response?.data?.message === "jwt expired" ||
            error?.response?.data?.message === "jwt malformed"
          ) {
            localStorage.removeItem("token");
            navigate("/login");
          }
          clearInterval(intervalId);
          setloading(false);
        });
    }, 1000);
  };

  const handleSubmit = () => {
    setOpenModal(true);
    setSubmissionStatus([]);
    setresult("Processing");
    const requestData = {
      languageId: languages.find((lang) => lang.value === language)?.id,
      code,
      problemSlug: slug,
      language: languages.find((lang) => lang.value === language)?.label,
      taskSlug,
    };

    service
      .post("/code/submit", requestData)
      .then((response) => {
        const { success, tokens, submissionId } = response;
        if (success) {
          pollSubmissionStatus(submissionId, tokens);
        } else {
          setOutput("Failed to submit code");
          setresult("Submission Failed");
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.message === "jwt expired" ||
          error?.response?.data?.message === "jwt malformed"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
        console.error("Error submitting code:", error);
        setOutput("Failed to submit code");
        setresult("Submission Failed");
      });
  };

  const pollSubmissionStatus = (submissionId, tokens) => {
    let counter = 0;
    const intervalId = setInterval(() => {
      counter++;
      if (counter > 30) {
        clearInterval(intervalId);
        setRefreshData(true);
        return;
      }

      service
        .get(`/code/status/${submissionId}/?tokens=${tokens}`)
        .then((response) => {
          const { overallStatus, submissionStatuses } = response;
          if (overallStatus !== "Processing" && overallStatus !== "In Queue") {
            setSubmissionStatus(submissionStatuses);
            setresult(overallStatus);
            clearInterval(intervalId);
            setRefreshData(true);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.message === "jwt expired" ||
            error?.response?.data?.message === "jwt malformed"
          ) {
            localStorage.removeItem("token");
            navigate("/login");
          }
          console.error("Error polling submission status:", error);
          setOutput("Failed to get submission status");
          setresult("Submission Failed");
          clearInterval(intervalId);
          setRefreshData(true);
        });
    }, 3000);
  };

  const handleEditorDidMount = (editor, monaco) => {
    if (proctoring) {
      // Disable copy-paste actions
      editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyC, () => {});
      editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyV, () => {});
      editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyX, () => {});
      editor.addAction({
        id: "disable-context-menu",
        label: "Disable Context Menu",
        contextMenuGroupId: "navigation",
        contextMenuOrder: 1,
        run: (editor) => {},
      });
      editor.onContextMenu(() => {});
    }
  };

  useEffect(() => {
    if (proctoring) {
      // Disable right-click context menu
      const handleContextMenu = (e) => e.preventDefault();
      document.addEventListener("contextmenu", handleContextMenu);

      // Disable F12 and other inspect shortcuts
      const handleKeyDown = (e) => {
        if (
          (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I
          (e.ctrlKey && e.shiftKey && e.key === "C") || // Ctrl+Shift+C
          (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J
          (e.ctrlKey && e.key === "U") || // Ctrl+U
          e.key === "F12" // F12
        ) {
          e.preventDefault();
        }
      };
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        // Cleanup listeners on unmount
        document.removeEventListener("contextmenu", handleContextMenu);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [proctoring]);

  useEffect(() => {
    if (!proctoring) return;
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setTabSwitchCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount > 3 && !showWarning) {
            setShowWarning(true);
          }
          return newCount;
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [showWarning]);

  const handleCloseWarning = () => {
    setShowWarning(false);
  };
  return (
    <div
      className={`min-h-screen px-4 py-6 ${
        darkMode ? "bg-gray-900 text-gray-100" : "bg-white text-gray-900"
      } transition-colors duration-300`}
    >
      {showWarning && (
        <div className="mx-4 fixed top-8 right-8 p-16 bg-red-600 text-white rounded-lg shadow-lg z-50">
          <div className="flex items-center">
            <span className="text-2xl mr-2">⚠️</span>
            <p className="text-lg font-semibold">
              <span className="block mb-2">🚨 Final Warning 🚨</span>
              You have switched tabs {tabSwitchCount} times. If you switch tabs
              more than three times, your submission will be disqualified.
              Please ensure that you complete the test in a single tab to avoid
              any issues. 📋✏️
            </p>
          </div>
          <button
            onClick={handleCloseWarning}
            className="mt-2 px-4 py-2 bg-red-800 text-white rounded-lg"
          >
            Got it
          </button>
        </div>
      )}
      <div
        className={`flex justify-between items-center p-4 shadow-lg rounded-lg ${
          darkMode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <div className="w-full flex flex-wrap justify-center items-center gap-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg">
          <div className="flex items-center space-x-2">
            <select
              id="theme"
              value={theme}
              onChange={(e) => setTheme(e.target.value)}
              className="rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:text-white text-gray-900 font-semibold shadow-sm focus:ring-2 focus:ring-amber-400 focus:outline-none"
            >
              {themes.map((themeOption, ind) => (
                <option
                  key={ind}
                  value={themeOption.value}
                  className="bg-white dark:bg-gray-700 text-gray-900 dark:text-white font-semibold text-center"
                >
                  {themeOption.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-2">
            <select
              id="language"
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
                setCode("");
              }}
              className="rounded-md border border-gray-300 bg-white dark:bg-gray-700 dark:text-white text-gray-900 font-semibold shadow-sm focus:ring-2 focus:ring-amber-400 focus:outline-none"
            >
              {languages.map((lang) => (
                <option
                  key={lang.value}
                  value={lang.value}
                  className="bg-white dark:bg-gray-700 text-gray-900 dark:text-white font-semibold text-center"
                >
                  {lang.label}
                </option>
              ))}
            </select>
          </div>

          <Tooltip content="Reset Code" placement="top">
            <BiReset
              onClick={() => setDefaultCode(language)}
              className="text-amber-400 hover:text-amber-500 transition duration-300 ease-in-out cursor-pointer"
              size={30}
            />
          </Tooltip>

          <button
            onClick={toggleTheme}
            className={`p-2 rounded-lg border transition-colors duration-300 ${
              darkMode
                ? "border-gray-600 bg-gray-800 text-gray-100"
                : "border-gray-300 bg-white text-gray-900"
            }`}
          >
            {darkMode ? "🌞 Light Mode" : "🌙 Dark Mode"}
          </button>
        </div>
      </div>

      <div
        className={`bg-white p-1 my-4 rounded-lg shadow-md ${
          darkMode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <Editor
          height="350px"
          width="100%"
          language={language}
          value={code}
          onChange={(value) => handleCodeChange(value)}
          theme={theme}
          onMount={handleEditorDidMount}
          options={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 4,
            fontSize: 14,
            scrollBeyondLastLine: false,
            minimap: { enabled: false },
          }}
          className="w-full rounded-md"
        />
      </div>

      <div
        className={`bg-white p-4 border-t-2 rounded-lg shadow-md ${
          darkMode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <div className="flex justify-between items-center mb-4">
          <button
            disabled={
              code.length === 0 ||
              output === "Running..." ||
              output === "Processing" ||
              output === "In Queue"
            }
            className={`${
              code.length === 0 ||
              output === "Running..." ||
              output === "Processing" ||
              output === "In Queue"
                ? "bg-green-200 cursor-not-allowed"
                : "bg-green-400 hover:bg-green-500"
            } font-bold text-white px-4 py-2 rounded flex items-center space-x-2 shadow-lg transition duration-300 ease-in-out`}
            onClick={handleRun}
          >
            <MdPlayCircle size={25} />
            <span>{output === "Running..." ? "Running" : "Run"}</span>
          </button>

          <button
            disabled={
              code.length === 0 ||
              output === "Running..." ||
              output === "Processing" ||
              output === "In Queue"
            }
            className={`${
              code.length === 0
                ? "bg-amber-300 cursor-not-allowed"
                : "bg-amber-500 hover:bg-amber-600"
            } font-bold text-white px-4 py-2 rounded flex items-center space-x-2 shadow-lg transition duration-300 ease-in-out`}
            onClick={handleSubmit}
          >
            <FaCloudUploadAlt size={25} />
            <span>Submit</span>
          </button>
        </div>

        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Input"
          className={`w-full h-24 p-3 border ${
            darkMode
              ? "border-gray-700 bg-gray-800 text-gray-100"
              : "border-amber-400 bg-white text-gray-900"
          } rounded-lg resize-none shadow-lg caret-amber-500 focus:caret-amber-500 focus:border-amber-500 focus:ring focus:ring-amber-300 focus:outline-none transition duration-300 ease-in-out`}
        />

        <textarea
          value={output}
          disabled
          placeholder="Output"
          className={`w-full h-24 p-3 mt-4 border ${
            darkMode
              ? "border-gray-700 bg-gray-800 text-gray-100"
              : "border-amber-300 bg-gray-50 text-gray-900"
          } rounded-lg resize-none shadow-lg cursor-not-allowed`}
        />
      </div>

      <Modal show={openModal} onClose={handleCloseModal} size={"3xl"}>
        <Modal.Header>
          <div className="flex justify-center items-center gap-4">
            {result === "Processing" && <Spinner color="warning" size="xl" />}
            <p
              className={`rounded-lg p-2 font-bold text-center ${
                result === "Accepted"
                  ? "text-green-500"
                  : result === "Processing"
                  ? "text-blue-500"
                  : "text-red-500"
              }`}
            >
              <span className="text-2xl">{getEmoji(result)}</span>
              <span className="ml-2">{result}</span>
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <table
              className={`my-2 w-full border ${
                darkMode ? "border-gray-700" : "border-gray-300"
              }`}
            >
              <thead className={`${darkMode ? "bg-gray-800" : "bg-gray-200"}`}>
                <tr>
                  <th
                    className={`px-4 py-2 ${
                      darkMode
                        ? "text-gray-100 border-gray-700"
                        : "text-gray-800 border-gray-300"
                    }`}
                  >
                    Testcase
                  </th>
                  <th
                    className={`px-4 py-2 ${
                      darkMode
                        ? "text-gray-100 border-gray-700"
                        : "text-gray-800 border-gray-300"
                    }`}
                  >
                    Verdict
                  </th>
                </tr>
              </thead>
              <tbody className={`${darkMode ? "bg-gray-800" : ""}`}>
                {submissionsStatus?.map((verdict, index) => (
                  <tr key={index}>
                    <td
                      className={`px-4 py-2 text-center ${
                        darkMode
                          ? "text-gray-100 border-gray-700"
                          : "text-gray-900 border-gray-300"
                      }`}
                    >
                      {index + 1}
                    </td>
                    <td
                      className={`px-4 py-2 text-center ${
                        verdict === "Accepted"
                          ? "text-green-500"
                          : "text-red-500"
                      } ${darkMode ? "border-gray-700" : "border-gray-300"}`}
                    >
                      {verdict}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <pre
            className={`p-4 rounded-md overflow-auto ${
              darkMode
                ? "bg-gray-900 text-gray-100"
                : "bg-gray-100 text-gray-900"
            }`}
          >
            {code}
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} color="warning">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CodeEditor;
