import React, { useState, useEffect } from "react";
import ApiService from "./ApiService";
import { useParams } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { Modal } from "flowbite-react";
import * as XLSX from "xlsx";

const PlagiarismReport = () => {
  const { batchId,taskId } = useParams();
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const service = new ApiService();

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setLoading(true);
        const data = await service.get(`/task/plagiarism/${taskId}`);
        setReport(data);
      } catch (error) {
        console.error("Error fetching plagiarism report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [taskId]);

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(
        report.map((item, index) => ({
            "S.No": index + 1,
            "User 1": `${item.user1.fullname} (${item.user1.rollNumber})`,
            "Code 1": item.user1.code,  // Add code of user 1
            "Submission Time 1": new Date(item.user1.createdAt).toLocaleString(),
            "User 2": `${item.user2.fullname} (${item.user2.rollNumber})`,
            "Code 2": item.user2.code,  // Add code of user 2
            "Submission Time 2": new Date(item.user2.createdAt).toLocaleString(),
            Similarity: `${(item.similarity * 100).toFixed(2)}%`
          }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Plagiarism Report");
    XLSX.writeFile(wb, `Plagiarism_Report_${batchId}.xlsx`);
  };

  const handleModalOpen = (reportItem) => {
    setSelectedReport(reportItem);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ImSpinner2 className="animate-spin text-4xl text-amber-500" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Plagiarism Report</h1>
      <button
        onClick={handleDownload}
        className="mb-4 px-4 py-2 bg-amber-500 text-white rounded-lg hover:bg-amber-600"
      >
        Download Report
      </button>
      {report.length === 0 ? (
        <p>No plagiarism detected.</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">User 1</th>
              <th className="py-2 px-4 border-b">User 2</th>
              <th className="py-2 px-4 border-b">Similarity</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {report.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">
                  {item.user1.fullname} ({item.user1.rollNumber})
                </td>
                <td className="py-2 px-4 border-b">
                  {item.user2.fullname} ({item.user2.rollNumber})
                </td>
                <td className="py-2 px-4 border-b">
                  {(item.similarity * 100).toFixed(2)}%
                </td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleModalOpen(item)}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    View Codes
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal for viewing codes */}
      {selectedReport && (
        <Modal show={isModalOpen} onClose={handleModalClose}>
          <Modal.Header>Plagiarism Details</Modal.Header>
          <Modal.Body>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <h3 className="font-semibold mb-2">{selectedReport.user1.fullname} ({selectedReport.user1.rollNumber})</h3>
                <p className="text-sm text-gray-600 mb-2">
                  Submission Time: {new Date(selectedReport.user1.createdAt).toLocaleString()}
                </p>
                <pre className="whitespace-pre-wrap p-4 bg-gray-100 border border-gray-300 rounded-lg">
                  {selectedReport.user1.code}
                </pre>
              </div>
              <div className="w-1/2">
                <h3 className="font-semibold mb-2">{selectedReport.user2.fullname} ({selectedReport.user2.rollNumber})</h3>
                <p className="text-sm text-gray-600 mb-2">
                  Submission Time: {new Date(selectedReport.user2.createdAt).toLocaleString()}
                </p>
                <pre className="whitespace-pre-wrap p-4 bg-gray-100 border border-gray-300 rounded-lg">
                  {selectedReport.user2.code}
                </pre>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleModalClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default PlagiarismReport;
