import { Button, Modal } from "flowbite-react";
import { useState, useEffect } from "react";
import ApiService from "./ApiService";
import { useParams } from "react-router-dom";
import { IoCopyOutline } from "react-icons/io5";
import moment from "moment";
import Loader from "./Loader"; // Import the Loader component

const MySubmissions = ({ refreshdata }) => {
  const [loading, setLoading] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { slug, taskSlug } = useParams();
  const [submissions, setSubmissions] = useState([]);
  const [copied, setCopied] = useState(false); // State for copied message
  const service = new ApiService();

  useEffect(() => {
    fetchSubmissions();
  }, [refreshdata, slug]);

  const fetchSubmissions = async () => {
    setLoading(true);
    try {
      const response = await service.get(`/user/submissions/?slug=${slug}&taskSlug=${taskSlug}`);
      setSubmissions(response.submissions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching submissions:", error);
      setLoading(false);
    }
  };

  const handleViewCode = (submission) => {
    setSelectedSubmission(submission);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(selectedSubmission?.code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="container mx-auto px-4 py-8 font-sans">
      {loading ? (
        <Loader /> // Display the loader when loading
      ) : (
        <>
          {submissions.length === 0 ? (
            <p className="text-center text-gray-600">No submissions yet</p>
          ) : (
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Language
                  </th>
                  <th className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Code
                  </th>
                  <th className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {submissions.map((submission, ind) => (
                  <tr key={submission._id}>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      {ind + 1}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium ${
                        submission.status === "Accepted" ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      {submission.status}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      {submission.language}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
                      <button className="text-blue-600 hover:text-blue-900" onClick={() => handleViewCode(submission)}>
                        View Code
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                      {submission.createdAt ? moment(submission.createdAt).fromNow() : "one eternity ago"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <Modal show={openModal} onClose={handleCloseModal}>
            <Modal.Header>Submitted Code</Modal.Header>
            <Modal.Body>
              <div className="relative">
                <div className="flex justify-center">
                  <table className="my-2 bg-gray-100 border border-2 w-full">
                    <thead className="border border-2">
                      <tr>
                        <th className="px-4 py-2">Testcase</th>
                        <th className="px-4 py-2">Verdict</th>
                      </tr>
                    </thead>
                    <tbody  className="border border-2">
                      {selectedSubmission?.results?.map((result, index) => (
                        <tr key={index}>
                          <td className="px-4 py-2 text-center border border-2">{index + 1}</td>
                          <td className={`border border-2 text-center px-4 py-2 ${result === "Accepted" ? "text-green-500" : "text-red-500"}`}>
                            {result}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="relative">
                  <IoCopyOutline
                    className="absolute top-0 right-0 mr-4 mt-4 cursor-pointer text-white z-10"
                    onClick={handleCopyCode}
                  />
                  {copied && (
                    <span className="absolute top-0 right-0 mr-4 mt-4 bg-green-500 text-white px-2 py-1 rounded text-xs z-10">
                      Copied
                    </span>
                  )}
                  <pre className="bg-gray-900 text-white p-4 w-full overflow-auto">
                    {selectedSubmission?.code}
                  </pre>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default MySubmissions;
