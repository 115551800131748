import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Loader from "./Loader";
import ApiService from "./ApiService";

const Clientleaderboard = () => {
  const apiService = new ApiService();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [role, setRole] = useState("student");
  const { id, taskSlug } = useParams();

  const fetchLeaderboard = async () => {
    setLoading(true);
    try {
      const response = await apiService.get(`/task/leaderboard/client/${id}`);
      setData(response.data);
      setRole(response.role);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, [id]);

  const downloadExcel = () => {
    // Filter out users with missing email
    const filteredData = data
      .filter(user => user.email) // Remove users with missing email
      .map((user, index) => {
        const totalProblems = user.totalEasy + user.totalMedium + user.totalHard;
        const totalScore = user.totalEasy * 10 + user.totalMedium * 20 + user.totalHard * 30;
        const score = user.easySolved * 10 + user.mediumSolved * 20 + user.hardSolved * 30;
        const solveduser = user.easySolved  + user.mediumSolved  + user.hardSolved ;
        return {
          rank: index + 1,
          name: user.fullname,
          email: user.email,
          batchId: user.batchId,
          easySolved: `${user.easySolved}/${user.totalEasy}`,
          mediumSolved: `${user.mediumSolved}/${user.totalMedium}`,
          hardSolved: `${user.hardSolved}/${user.totalHard}`,
          problemsSolved:`${solveduser}/${totalProblems}`,
          score: `${score}/${totalScore}`
        };
      });

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${taskSlug}`);
    XLSX.writeFile(workbook, `${taskSlug}.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="px-4 py-8 bg-gray-900 min-h-screen">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-3xl font-bold text-amber-500">Task Leaderboard</h2>
        <p className="text-gray-500 text-sm mt-4 text-center">
          Leaderboard data will be updated every 10 minutes.
        </p>
      
          <button
            onClick={downloadExcel}
            className="bg-amber-500 text-white px-4 py-2 rounded-lg hover:bg-amber-600"
          >
            Download Excel
          </button>
       
      </div>
      <div className="overflow-x-auto shadow-lg rounded-lg">
        <table className="w-full table-auto">
          <thead className="bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Rank
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                BatchId
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Easy Problems Solved
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Medium Problems Solved
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Hard Problems Solved
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Total Problems Solved
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Score
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-800 divide-y divide-gray-700">
            {data.map((user, index) => {
              const totalProblems = user.totalEasy + user.totalMedium + user.totalHard;
              const totalScore = user.totalEasy * 10 + user.totalMedium * 20 + user.totalHard * 30;
              const score = user.score
              return (
                <tr key={user.userId} className="hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {user?.fullname}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {user?.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {user?.batchId}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {user.easySolved}/{user.totalEasy}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {user.mediumSolved}/{user.totalMedium}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {user.hardSolved}/{user.totalHard}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {user.problemsSolved}/{totalProblems}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {score}/{totalScore}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Clientleaderboard;
