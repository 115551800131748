import React, { useState, useEffect } from "react";
import ApiService from "./ApiService";
import { useParams } from "react-router-dom";
import { Spinner, Badge } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { IoCopyOutline } from "react-icons/io5";
import { convert } from "html-to-text";
import "quill/dist/quill.snow.css";
import { useTheme } from "./ThemeContext";

const ViewProblemPage = () => {
  const { darkMode } = useTheme();
  const [loading, setLoading] = useState(false);
  const [problem, setProblem] = useState(null);
  const [copiedTestCases, setCopiedTestCases] = useState([]);
  const { slug } = useParams();
  const navigate = useNavigate();
  const service = new ApiService();

  useEffect(() => {
    setLoading(true);
    service
      .get(`/admin/problem/slug/${slug}`)
      .then((data) => {
        setProblem(data.problem);
        localStorage.setItem(
          `defaultCode-${slug}`,
          JSON.stringify(data.problem.defaultCode)
        );
      })
      .catch((err) => {
        console.error("Error Fetching Problem: ", err);
        if (
          err?.response?.data?.message === "jwt expired" ||
          err?.response?.data?.message === "jwt malformed"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug]);

  const stripHtmlTags = (html) => {
    let text = convert(html, {
      wordwrap: false,
      uppercaseHeadings: false,
      ignoreImage: true,
    });
    text = text.trim();
    text = text
      .split("\n")
      .filter((line) => line.trim() !== "")
      .join("\n");
    return text;
  };

  const copyToClipboard = (htmlContent, index) => {
    const plainTextContent = stripHtmlTags(htmlContent);
    navigator.clipboard
      .writeText(plainTextContent)
      .then(() => {
        setCopiedTestCases((prev) => [...prev, index]);
        setTimeout(() => {
          setCopiedTestCases((prev) => prev.filter((item) => item !== index));
        }, 2000);
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard:", error);
      });
  };

  return (
    <div
      className={`min-h-screen px-4 py-6 ${
        darkMode ? "bg-gray-900 text-gray-100" : "bg-gray-50 text-gray-900"
      } transition-colors duration-300`}
    >
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Spinner color="warning" aria-label="Loading..." size="xl" />
        </div>
      ) : problem ? (
        <div className="space-y-2">
          <div
            className={`p-6 rounded-lg shadow-md ${
              darkMode
                ? "bg-gray-800 border border-gray-700"
                : "bg-white border border-gray-200"
            }`}
          >

            <div className="flex items-center justify-between mb-4">
            <header className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-extrabold capitalize">
                {problem?.title || "Loading..."}
              </h1>
            </header>
              <span
                className={`py-1 px-3 rounded-lg text-sm font-semibold ${getDifficultyColor(
                  problem.difficultyLevel,
                  darkMode
                )}`}
              >
                {problem.difficultyLevel}
              </span>
            </div>
            <div
              className={`p-6 rounded-lg shadow-md ${
                darkMode
                  ? "bg-gray-800 border border-gray-700"
                  : "bg-white border border-gray-200"
              }`}
            >
              <p className="text-lg font-semibold mb-2">Description:</p>
              <div
                className={`p-4 rounded-md ${
                  darkMode ? "bg-gray-700" : "bg-gray-100"
                } dark:bg-gray-700`}
                dangerouslySetInnerHTML={{ __html: problem.description }}
              />
            </div>
          </div>
          <div
            className={`p-6 rounded-lg shadow-md ${
              darkMode
                ? "bg-gray-800 border border-gray-700"
                : "bg-white border border-gray-200"
            }`}
          >
            <p className="text-lg font-semibold mb-2">Input Format:</p>
            <div
              className={`p-4 rounded-md ${
                darkMode ? "bg-gray-700" : "bg-gray-100"
              }`}
              dangerouslySetInnerHTML={{ __html: problem.inputFormat }}
            />
          </div>
          <div
            className={`p-6 rounded-lg shadow-md ${
              darkMode
                ? "bg-gray-800 border border-gray-700"
                : "bg-white border border-gray-200"
            }`}
          >
            <p className="text-lg font-semibold mb-2">Output Format:</p>
            <div
              className={`p-4 rounded-md ${
                darkMode ? "bg-gray-700" : "bg-gray-100"
              }`}
              dangerouslySetInnerHTML={{ __html: problem.outputFormat }}
            />
          </div>
          <div
            className={`p-6 rounded-lg shadow-md ${
              darkMode
                ? "bg-gray-800 border border-gray-700"
                : "bg-white border border-gray-200"
            }`}
          >
            <p className="text-lg font-semibold mb-2">Constraints:</p>
            <div
              className={`p-4 rounded-md font-mono ${
                darkMode ? "bg-gray-700" : "bg-gray-100"
              }`}
              dangerouslySetInnerHTML={{ __html: problem.constraints }}
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Public Test Cases:</h2>
            {problem.publicTestcases.map((testCase, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg shadow-md mb-4 border ${
                  darkMode
                    ? "bg-gray-800 border-gray-700"
                    : "bg-white border-gray-200"
                }`}
              >
                <div className="flex justify-between items-center mb-1">
                  <h3 className="text-lg font-semibold">
                    Test Case {index + 1}
                  </h3>
                  <IoCopyOutline
                    onClick={() => copyToClipboard(testCase.input, index)}
                    className={`text-gray-500 hover:text-blue-600 cursor-pointer transition-transform transform hover:scale-110 ${
                      copiedTestCases.includes(index) ? "text-green-600" : ""
                    }`}
                    size={20}
                  />
                </div>
                <div className="space-y-2">
                  <div>
                    <span className="font-semibold">Input:</span>
                    <div
                      className={`p-3 rounded-md ${
                        darkMode ? "bg-gray-700" : "bg-gray-100"
                      }`}
                      dangerouslySetInnerHTML={{ __html: testCase.input }}
                    />
                  </div>
                  <div>
                    <span className="font-semibold">Output:</span>
                    <div
                      className={`p-3 rounded-md ${
                        darkMode ? "bg-gray-700" : "bg-gray-100"
                      }`}
                      dangerouslySetInnerHTML={{ __html: testCase.output }}
                    />
                  </div>
                  <div>
                    <span className="font-semibold">Explanation:</span>
                    <div
                      className={`p-3 rounded-md ${
                        darkMode ? "bg-gray-700" : "bg-gray-100"
                      }`}
                      dangerouslySetInnerHTML={{ __html: testCase.explanation }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`p-6 rounded-lg shadow-md ${
              darkMode
                ? "bg-gray-800 border border-gray-700"
                : "bg-white border border-gray-200"
            }`}
          >
            <p className="text-lg font-semibold mb-2">Tags:</p>
            <div className="flex flex-wrap gap-2">
              {problem.tags.map((tag) => (
                <Badge
                  key={tag}
                  color="success"
                  className={`bg-green-200 text-green-800 ${
                    darkMode ? "bg-green-800 text-green-100" : ""
                  }`}
                >
                  {tag}
                </Badge>
              ))}
            </div>
          </div>
          <div
  className={`p-6 rounded-lg shadow-md ${
    darkMode ? "bg-gray-800 border border-gray-700" : "bg-white border border-gray-200"
  }`}
>
  <div className="flex justify-between items-center">
    <p className="text-lg font-semibold">
      Author: <span className="font-normal">{problem.author.fullname}</span>
    </p>
    {problem.createdAt && (
      <p className="text-lg font-semibold">
        Created At: <span className="font-normal">{new Date(problem.createdAt).toLocaleString()}</span>
      </p>
    )}
  </div>
</div>

        </div>
      ) : (
        <div className="text-center text-lg font-semibold">
          No problem found
        </div>
      )}
    </div>
  );
};

const getDifficultyColor = (difficultyLevel, darkMode) => {
  switch (difficultyLevel) {
    case "easy":
      return darkMode
        ? "text-green-400 bg-gray-800"
        : "text-green-500 bg-green-100";
    case "medium":
      return darkMode
        ? "text-yellow-400 bg-gray-800"
        : "text-yellow-500 bg-yellow-100";
    case "hard":
      return darkMode ? "text-red-400 bg-gray-800" : "text-red-500 bg-red-100";
    default:
      return "";
  }
};

export default ViewProblemPage;
