import React from "react";
import { Spinner } from "flowbite-react";
const Loader = () => {
  return (
    <div className="flex flex-col justify-center text-center">
      <Spinner
        color="warning"
        aria-label="Please Wait Loading...."
        size={"xl"}
      />
    </div>
  );
};

export default Loader;
