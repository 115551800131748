import React, { useState, useEffect } from "react";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import Editor from "./Editor";
import ViewProblemPage from "./ViewProblemPage";
import { TbFileDescription } from "react-icons/tb";
import { BsJournalCode } from "react-icons/bs";
import MySubmissions from "./MySubmissions";
import { AiOutlineSolution } from "react-icons/ai";
import VirtualContestHeader from "./VirtualContestHeader";

const VirtualContestBody = () => {
  const [refreshdata, setrefreshData] = useState(false);
  const [activeSection, setActiveSection] = useState("description");
  const renderSection = () => {
    switch (activeSection) {
      case "description":
        return <ViewProblemPage />;
      case "submissions":
        return <MySubmissions refreshdata={refreshdata} />;
      case "editorial":
        return <p className="text-center text-gray-600">Coming Soon</p>;
      default:
        return null;
    }
  };


  return (
    <div>
      <VirtualContestHeader/>
      <div className="bg-white">
        <SplitterLayout
          percentage={true}
          secondaryInitialSize={50}
          vertical={false}
        >
          <div className="flex flex-col w-full">
            <div className="flex w-full md:flex-row flex-col bg-white text-white border-b-2">
              <button
                onClick={() => setActiveSection("description")}
                className={`flex-1 p-1 text-center ${
                  activeSection === "description" ? "bg-sky-500" : "bg-sky-400"
                }`}
              >
                <TbFileDescription size={30} className="inline-block mr-2" />{" "}
                Description
              </button>
              <button
                onClick={() => setActiveSection("submissions")}
                className={`flex-1 p-1 text-center ${
                  activeSection === "submissions" ? "bg-sky-500" : "bg-sky-400"
                }`}
              >
                <BsJournalCode size={30} className="inline-block mr-2" /> My
                Submissions
              </button>
              <button
                onClick={() => setActiveSection("editorial")}
                className={`flex-1 p-1 text-center ${
                  activeSection === "editorial" ? "bg-sky-500" : "bg-sky-400"
                }`}
              >
                <AiOutlineSolution size={30} className="inline-block mr-2" />{" "}
                Editorial
              </button>
            </div>
            <div className="flex-1 overflow-auto">{renderSection()}</div>
          </div>
          <div className="flex-1">
            <Editor setrefreshData={setrefreshData} />
          </div>
        </SplitterLayout>
      </div>
    </div>
  );
};

export default VirtualContestBody;
