import React, { useState, useEffect } from "react";
import { Link,useParams, useNavigate, } from "react-router-dom";
import ApiService from "./ApiService";
import { Spinner, Button } from "flowbite-react";
import { FaList, FaWindowClose } from "react-icons/fa";
import moment from "moment";

const VirtualContestHeader = () => {
  const { taskSlug, slug } = useParams(); // Capture problemSlug from URL
  const [task, setTask] = useState({});
  const [loading, setLoading] = useState(false);
  const [problems, setProblems] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [timeLeft, setTimeLeft] = useState({});
  const navigate = useNavigate();
  const service = new ApiService();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const fetchProblems = async () => {
    setLoading(true);
    try {
      const response = await service.get(`/task/slug/${taskSlug}`);
      setTask(response?.data);
      setProblems(response?.data?.problems || []);
    } catch (err) {
      console.error("Error fetching selected problems:", err);
      if (
        err?.response?.data?.message === "jwt expired" ||
        err?.response?.data?.message === "jwt malformed"
      ) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProblems();
  }, [taskSlug]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const startTime = localStorage.getItem("contestStartTime");
      const contestTimeLimit = localStorage.getItem("contestTimeLimit");

      if (startTime && contestTimeLimit) {
        const endTime = moment(startTime).add(parseInt(contestTimeLimit), 'minutes');
        const now = moment();
        if (now.isBefore(endTime)) {
          const duration = moment.duration(endTime.diff(now));
          setTimeLeft({
            days: Math.floor(duration.asDays()),
            hours: duration.hours(),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
          });
        } else {
          localStorage.removeItem("contestStartTime");
          localStorage.removeItem("contestTimeLimit");
          localStorage.removeItem("contestSlug");
          setTimeLeft({});
          navigate("/virtual-contests");
        }
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center">
          <Spinner className="text-amber-400" size={"xl"} />
        </div>
      ) : (
        <div>
          <div className="w-full border-b-2">
            <div className="flex uppercase text-black gap-1">
              <div className="flex justify-center flex-col ml-2">
                <Button
                  onClick={toggleDrawer}
                  color="warning"
                  className="bg-amber-500 rounded-full font-bold text-white hover:bg-amber-600 uppercase shadow-lg p-1"
                >
                  <FaList size={20} />
                </Button>
              </div>
              <div className="w-1/3 flex justify-center flex-col m-1">
                <div className="text-xl font-bold text-green-400 text-center uppercase rounded-lg border border-gray-950 py-2">
                  {task.title}
                </div>
              </div>

              <div className="w-1/3 text-sm">
                <div className="flex flex-row justify-center gap-0">
                  <div className="flex flex-col justify-center items-center p-1">
                    <div className="border border-gray-950 p-1 w-16 text-center text-xl text-sky-500">
                      {timeLeft.days || 0}
                    </div>
                    <div>days</div>
                  </div>
                  <div className="flex flex-col justify-center items-center p-1 text-lg font-bold mb-5">
                    :
                  </div>
                  <div className="flex flex-col justify-center items-center p-1">
                    <div className="border border-gray-950 p-1 w-16 text-center text-xl text-sky-500">
                      {timeLeft.hours || 0}
                    </div>
                    <div>hrs</div>
                  </div>
                  <div className="flex flex-col justify-center items-center p-1 text-lg font-bold mb-5">
                    :
                  </div>
                  <div className="flex flex-col justify-center items-center p-1">
                    <div className="border border-gray-950 p-1 w-16 text-center text-xl text-sky-500">
                      {timeLeft.minutes || 0}
                    </div>
                    <div>mins</div>
                  </div>
                  <div className="flex flex-col justify-center items-center p-1 text-lg font-bold mb-5">
                    :
                  </div>
                  <div className="flex flex-col justify-center items-center p-1">
                    <div className="border border-gray-950 p-1 w-16 text-center text-xl text-sky-500">
                      {timeLeft.seconds || 0}
                    </div>
                    <div>secs</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`fixed top-0 left-0 z-20 h-full transition-all duration-500 transform bg-white shadow-lg ${isOpen ? "translate-x-0" : "-translate-x-full"}`}>
            <div className="m-2">
              <div className="flex justify-end m-1">
                <FaWindowClose
                  onClick={toggleDrawer}
                  size={30}
                  className="text-amber-500 hover:cursor-pointer"
                />
              </div>
              <div className="flex justify-center mb-2">
                <div>
                  <img
                    src="/chaturaIT.png"
                    alt="ChaturaIT Logo"
                    className="w-64 h-auto object-contain"
                  />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                <div>
                  <img
                    src="/divider.png"
                    alt="Divider Logo"
                    className="w-64 h-auto object-contain bg-white"
                  />
                </div>
              </div>

              <div className="max-h-screen rounded-lg flex flex-col overflow-auto">
                    <table className="divide-y divide-gray-200">
                      <tbody className="bg-white divide-y divide-gray-200">
                        {problems?.map((problem, ind) => (
                          <tr
                            key={ind}
                            className={
                              ind % 2 === 0 ? "bg-gray-50" : "bg-white"
                            }
                          >
                            <td
                              className={
                                slug === problem.slug
                                  ? `w-full px-6 py-4 whitespace-nowrap text-white bg-sky-500`
                                  : `w-full px-6 py-4 whitespace-nowrap hover:cursor-pointer bg-gray-50`
                              }
                            >
                              <Link
                                className="w-full"
                                onClick={toggleDrawer}
                                to={`/virtual-contest/${taskSlug}/problem/${problem?.slug}`}
                              >
                                {problem?.title}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtualContestHeader;
