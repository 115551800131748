import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ApiService from "./ApiService";
import { Pagination, Select } from "flowbite-react"; // Adjust imports as per your actual components
import moment from "moment"; // Import Moment.js for date formatting
import Loader from "./Loader";

const PlagiarismReportsPage = () => {
  const { taskId } = useParams(); // Fetch taskId from URL params
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [user1Id, setUser1Id] = useState("");
  const [user2Id, setUser2Id] = useState("");
  const [percentage, setPercentage] = useState(""); // Single percentage state
  const [selectedReport, setSelectedReport] = useState(null); // State to track selected report
  const service = new ApiService();

  useEffect(() => {
    fetchReports();
  }, [currentPage, limit, taskId, user1Id, user2Id, percentage]);

  const fetchReports = () => {
    setLoading(true);
    const url = `/task/plagiarism-report-get?page=${currentPage}&limit=${limit}&taskId=${taskId}&user1Id=${user1Id}&user2Id=${user2Id}&percentage=${percentage}`;

    service
      .get(url)
      .then((response) => {
        setReports(response.reports);
        setTotalPages(response.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching reports:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const handleUser1IdChange = (e) => {
    setUser1Id(e.target.value);
  };

  const handleUser2IdChange = (e) => {
    setUser2Id(e.target.value);
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const handleRowClick = (report) => {
    setSelectedReport(report); // Set the selected report to show its details
  };

  const handleCloseDetails = () => {
    setSelectedReport(null); // Clear selected report to hide details
  };

  return (
    <div className="container mx-auto p-4 font-sans">
      <h1 className="text-3xl font-bold mb-4 text-center">
        Plagiarism Reports for Task {taskId}
      </h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-wrap items-center justify-between mb-4">
          <div className="flex items-center space-x-4 mb-2 sm:mb-0">
            <input
              type="text"
              name="user1Id"
              placeholder="User 1 ID"
              value={user1Id}
              onChange={handleUser1IdChange}
              className="border rounded px-3 py-2"
            />
            <input
              type="text"
              name="user2Id"
              placeholder="User 2 ID"
              value={user2Id}
              onChange={handleUser2IdChange}
              className="border rounded px-3 py-2"
            />
            <input
              type="number"
              name="percentage"
              min="0"
              max="100"
              value={percentage}
              onChange={handlePercentageChange}
              className="border rounded px-3 py-2"
              placeholder="Percentage"
            />
          </div>
          <Select
            value={limit}
            onChange={handleLimitChange}
            className="form-select"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </Select>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2 text-left">Task ID</th>
                    <th className="px-4 py-2 text-left">User 1 ID</th>
                    <th className="px-4 py-2 text-left">User 2 ID</th>
                    <th className="px-4 py-2 text-left">Matching Lines</th>
                    <th className="px-4 py-2 text-left">Percentage</th>
                    <th className="px-4 py-2 text-left">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((report) => (
                    <tr
                      key={report._id}
                      className="bg-white cursor-pointer"
                      onClick={() => handleRowClick(report)}
                    >
                      <td className="px-4 py-2">{report.taskId.title}</td>
                      <td className="px-4 py-2">{report.user1Id.fullname}</td>
                      <td className="px-4 py-2">{report.user2Id.fullname}</td>
                      <td className="px-4 py-2">{report.matchingLines}</td>
                      <td className="px-4 py-2">{report.percentage}%</td>
                      <td className="px-4 py-2">
                        {moment(report.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Modal for showing matched code */}
            {selectedReport && (
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                  >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>

                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>

                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 className="text-lg font-medium text-gray-900">
                            Matching Code Details
                          </h3>
                          <div className="mt-4 grid grid-cols-2 gap-4">
                            {/* Submission 1 */}
                            <div className="bg-gray-900 text-white rounded-md p-4 overflow-auto">
                              <pre className="whitespace-pre-wrap">
                                {selectedReport.submission1Id.code}
                              </pre>
                              <p className="text-xs text-gray-400 mt-2">
                                Submitted At:{" "}
                                {moment(
                                  selectedReport.submission1Id.createdAt
                                ).format("MMMM Do YYYY, h:mm:ss a")}
                              </p>
                            </div>

                            {/* Submission 2 */}
                            <div className="bg-gray-900 text-white rounded-md p-4 overflow-auto">
                              <pre className="whitespace-pre-wrap">
                                {selectedReport.submission2Id.code}
                              </pre>
                              <p className="text-xs text-gray-400 mt-2">
                                Submitted At:{" "}
                                {moment(
                                  selectedReport.submission2Id.createdAt
                                ).format("MMMM Do YYYY, h:mm:ss a")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        onClick={handleCloseDetails}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              className="mt-4 flex justify-center"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PlagiarismReportsPage;
