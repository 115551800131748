import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
  },
  reducers: {
    loginUser: (state,action) => {
        state.user=action.payload.user
        localStorage.setItem("token",action.payload.token)
    },
    logoutUser: (state) => {
        localStorage.removeItem("token")
        state.user=null
    }
  },
});


export const { loginUser,logoutUser } = userSlice.actions;

export default userSlice.reducer;
