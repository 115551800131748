import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store from "./store";
import { Provider } from "react-redux";

// Use environment variable for client ID
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "355076565686-ej4v1fl8c6o913bus8rt5pqk1t78t8o4.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// Measure performance in development
if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
} else {
  reportWebVitals();
}
