export const getEmoji = (result) => {
    switch (result) {
      case "Processing":
        return "⌛";
      case "Accepted":
        return "🎉"; // Celebration emoji for Accepted
      case "Wrong Answer":
        return "❌"; // Cross mark emoji for Wrong Answer
      case "Time Limit Exceeded":
        return "⏰"; // Alarm clock emoji for Time Limit Exceeded
      case "Compilation Error":
        return "🛠️"; // Hammer and wrench emoji for Compilation Error
      case "Runtime Error":
        return "⚠️"; // Warning emoji for Runtime Errors
      case "Internal Error":
        return "⚙️"; // Gear emoji for Internal Error
      case "Exec Format Error":
        return "📝"; // Memo emoji for Exec Format Error
      case "Memory Limit Exceeded":
        return "🧠"; // Brain emoji for Memory Limit Exceeded
      case "Output Limit Exceeded":
        return "📤"; // Outbox emoji for Output Limit Exceeded
      case "Presentation Error":
        return "🖥️"; // Desktop emoji for Presentation Error
      case "System Error":
        return "💥"; // Collision emoji for System Error
      case "Security Violation":
        return "🔐"; // Locked emoji for Security Violation
      case "Restricted Function":
        return "🚫"; // No entry emoji for Restricted Function
      case "Judgement Failed":
        return "🧐"; // Face with monocle emoji for Judgement Failed
      default:
        return "🤔"; // Thinking emoji for Unknown Status or any other case
    }
  };
