import React from 'react';

const ChaturaitLearnings = () => {
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <div className="text-2xl font-bold text-blue-600">
            ChaturaitLearnings
          </div>
          <ul className="flex space-x-8 text-gray-700">
            <li><a href="#" className="hover:text-blue-500 transition duration-300">Home</a></li>
            <li><a href="#" className="hover:text-blue-500 transition duration-300">About</a></li>
            <li><a href="#" className="hover:text-blue-500 transition duration-300">Courses</a></li>
            <li><a href="#" className="hover:text-blue-500 transition duration-300">Contact</a></li>
          </ul>
        </div>
      </nav>

      {/* Header */}
      <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-24">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-extrabold mb-4">Welcome to ChaturaitLearnings</h1>
          <p className="text-xl mb-8">Empowering your learning journey with our top-notch online courses</p>
          <a href="#" className="bg-white text-blue-500 py-3 px-6 rounded-full text-lg hover:bg-gray-200 transition duration-300">Get Started</a>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto p-6 space-y-16">

        {/* Our Mission Section */}
        <section className="grid md:grid-cols-2 gap-8 items-center">
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Mission</h2>
            <p className="text-gray-700 mb-6">
              At ChaturaitLearnings, our mission is to provide accessible, high-quality education to individuals all over the world. We believe in the power of knowledge and aim to empower our learners to achieve their personal and professional goals.
            </p>
            <p className="text-gray-700">
              Whether you are looking to upskill, reskill, or simply learn something new, our diverse range of courses and expert instructors are here to guide you every step of the way.
            </p>
          </div>
          <img src="https://via.placeholder.com/600x400" alt="Our Mission" className="w-full h-auto rounded-lg shadow-md" />
        </section>

        {/* Popular Courses Section */}
        <section>
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-gray-800">Popular Courses</h2>
            <p className="text-gray-700">Explore our most popular courses and start learning today</p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <img src="https://via.placeholder.com/300x200" alt="Course 1" className="w-full h-auto rounded mb-4" />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Course Title 1</h3>
              <p className="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit.</p>
              <a href="#" className="text-blue-500 hover:text-blue-600">Learn More</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <img src="https://via.placeholder.com/300x200" alt="Course 2" className="w-full h-auto rounded mb-4" />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Course Title 2</h3>
              <p className="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit.</p>
              <a href="#" className="text-blue-500 hover:text-blue-600">Learn More</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <img src="https://via.placeholder.com/300x200" alt="Course 3" className="w-full h-auto rounded mb-4" />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Course Title 3</h3>
              <p className="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit.</p>
              <a href="#" className="text-blue-500 hover:text-blue-600">Learn More</a>
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="grid md:grid-cols-2 gap-8 items-center">
          <img src="https://via.placeholder.com/600x400" alt="Why Choose Us?" className="w-full h-auto rounded-lg shadow-md" />
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Why Choose Us?</h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Expert instructors with real-world experience</li>
              <li>Comprehensive and up-to-date course materials</li>
              <li>Flexible learning options to suit your schedule</li>
              <li>Interactive and engaging learning experience</li>
              <li>Community support and networking opportunities</li>
            </ul>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="bg-white py-16">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-12">What Our Students Say</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">"ChaturaitLearnings has been an incredible resource for me. The courses are well-structured and the instructors are very knowledgeable."</p>
                <h3 className="text-lg font-semibold text-gray-800">- Student A</h3>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">"I love the flexibility of the courses. I can learn at my own pace and on my own schedule."</p>
                <h3 className="text-lg font-semibold text-gray-800">- Student B</h3>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">"The community support has been amazing. I’ve connected with so many like-minded individuals."</p>
                <h3 className="text-lg font-semibold text-gray-800">- Student C</h3>
              </div>
            </div>
          </div>
        </section>

        {/* Subscription Section */}
        <section className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-16">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Stay Updated</h2>
            <p className="mb-8">Subscribe to our newsletter to get the latest updates and offers</p>
            <form className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
              <input type="text" id="firstname" name="firstname" placeholder="First Name" aria-label="First Name" required className="p-3 rounded-md border-2 border-transparent focus:border-white focus:outline-none text-gray-800" />
              <input type="email" id="email" name="email" placeholder="Email" aria-label="Email" required className="p-3 rounded-md border-2 border-transparent focus:border-white focus:outline-none text-gray-800" />
              <button type="submit" className="p-3 bg-white text-blue-500 rounded-md hover:bg-gray-200 transition duration-300" onClick={(e) => e.preventDefault()}>Subscribe</button>
            </form>
          </div>
        </section>

        {/* Blog Section */}
        <section className="py-16">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-12">Latest Blog Posts</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <img src="https://via.placeholder.com/300x200" alt="Blog Post 1" className="w-full h-auto rounded mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">Blog Post Title 1</h3>
                <p className="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit.</p>
                <a href="#" className="text-blue-500 hover:text-blue-600">Read More</a>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <img src="https://via.placeholder.com/300x200" alt="Blog Post 2" className="w-full h-auto rounded mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">Blog Post Title 2</h3>
                <p className="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit.</p>
                <a href="#" className="text-blue-500 hover:text-blue-600">Read More</a>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <img src="https://via.placeholder.com/300x200" alt="Blog Post 3" className="w-full h-auto rounded mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">Blog Post Title 3</h3>
                <p className="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor in reprehenderit.</p>
                <a href="#" className="text-blue-500 hover:text-blue-600">Read More</a>
              </div>
            </div>
          </div>
        </section>

      </main>

      {/* Footer */}
      <footer className="bg-gray-800 py-6">
        <div className="container mx-auto text-center text-white">
          <small>
            <a href="#" className="text-blue-400 hover:text-blue-500">Privacy Policy</a> • <a href="#" className="text-blue-400 hover:text-blue-500">Terms of Service</a>
          </small>
        </div>
      </footer>
    </div>
  );
}

export default ChaturaitLearnings;
